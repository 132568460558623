import { UUID } from 'angular2-uuid';
import { PriceTable } from '../project/assembly/assembly-view/calculation/approx-price/price';
import { AbstractAssemblyReference, AssemblyReference } from '../../pcb-common/assembly/assembly';
import { Address } from '../../common/customer';
import { Currency } from "../../common/currency";


export class QuotationListItem {
    team: String;
    quotationId: string;
    name: string;
    customerId: string;
    creator: string;
    status: QuotationStatus;
    created: string;
}

export class Quotation {
    quotationId: string;
    name: string;
    assembly: AbstractAssemblyReference;
    customerId: string;
    creator: string;
    contactId: string;
    status: QuotationStatus;
    created: string;
    validFor: number;
    billing?: Address;
    shipping?: Address;
    publicNotes: string;
    items: QuotationItemReference[] = [];
    requestDate: Date;
    origin: string;
}

export class QuotationStatus {
    name: QuotationStatusText;
    date: string;

    constructor(name: QuotationStatusText) {
        this.name = name;
        // this.date = new Date(now()).toDateString();
    }
}

export enum QuotationStatusText {
    DRAFT = 'draft',
    ARCHIVED = 'archived',
    OVERDUE = 'overdue',
    DELETED = 'deleted',
    SENT = 'sent',
    WON = 'won',
    LOST = 'lost'
}


export class QuotationCreation {
    name: string;
    contact: UUID;
    billing?: Address;
    shipping?: Address;
    validFor: number;
    items: QuotationItemCreation[] = [];
}

export class QuotationItem {
    id: UUID;
    name: string;
    description: string;
    assembly: AbstractAssemblyReference;
    info: QuotationItemInfo;
    won?: boolean;
}

export class QuotationItemReference {
    id: UUID;
    resolved: QuotationItem;
}

export class QuotationItemCreation {
    assembly: AssemblyReference;
    name: string;
    description: string;
    info: QuotationItemInfo;

    constructor(assembly: AssemblyReference) {
        this.assembly = assembly;
    }
}

export class QuotationItemInfo {
    specification: string;
    supplier: string;
    panel: string;
    technology: string;

    delivery: number;
    quantity: number;
    panelQuantity: number;
    nrePrice: number;
    unitPrice: number;
    total: number;

    userDelivery: number;
    userQuantity: number;
    userPanelQuantity: number;
    userNRE: number;
    userUnitPrice: number;
    userTotal: number;
    userMarkup: number;

    details: PriceTable[];
    unit = 'Pieces';
    position: number;
    id?: UUID;
    won?: boolean;
    currency: Currency;

}

export class QuotationConstants {
    public static QUOTATION_NAME = 'Quotations';
    public static QUOTATION_URL = 'quotations';
    public static QUOTATION_EDITOR_NAME = 'Quotation editor';
}